import React from 'react';
import styled from 'styled-components';
import BackgroundImage from "gatsby-background-image";
import { graphql, StaticQuery } from 'gatsby';
import OverlaySplash from '../beersPDP/OverlaySplash';
import { Fade } from "react-reveal"
import fonts from "../../styles/fonts"
import BlueLineImg from "../../resources/images/brewery/blue-line.svg"
import colors from "../../styles/colors"


const BackgroundStyled = styled.div`
    position: relative;
    height: 580px;
    margin-top: -138px;
    text-align: center;
    @media(max-width: 768px) {
        height: 420px;
    }
`;
const BackgroundImageStyled = styled(BackgroundImage)`
    width: 100vw;
    height: 100%;
    background-size: cover;
  `;

const Title = styled.div`
    padding-top: 181px;
    text-align: center;
    h1 {
      color: transparent;
      -webkit-text-stroke-width: 1.5px;
      -webkit-text-stroke-color: ${colors.white};
      text-transform: uppercase;
      ${fonts.swissBlackExtended};
      font-size: 46px;
      line-height: 46px;
      letter-spacing: 3px;
      padding-bottom: 27px;
    }
    img {
      width: 64px;
    }
    @media(min-width: 768px){
      padding-top: 230px;
      h1 {
        letter-spacing: 5px;
        line-height: 87px;
        font-size: 78px;
        padding-bottom: 30px;
      }
      img {
        width: 80px;
      }
    }
`

const HomeHero = () => (
  <StaticQuery query={graphql`
      query {
        background: file(relativePath: { eq: "net/hero-background.jpg" }) {
          childImageSharp {
            fluid(quality: 80, maxWidth: 5000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      const imageData = data.background.childImageSharp.fluid;
      return (
        <BackgroundStyled>
          <BackgroundImageStyled fluid={imageData} critical={true} durationFadeIn={100}>
            <Fade duration={750} delay={750}>
              <Title>
                <h1>My Net</h1>
                <img src={BlueLineImg} alt={"Line"}/>
              </Title>
            </Fade>
            <OverlaySplash />
          </BackgroundImageStyled>
        </BackgroundStyled>
      )
    }
    }
  />);

export default HomeHero;
