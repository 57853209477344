import React, { Component } from 'react';
import styled from 'styled-components';
import fonts from '../../styles/fonts';
import { Row, Col, Container } from 'styled-bootstrap-grid';
import { getCart } from "../../services/cart/cart"
import { getCheckout } from "../../store/reducers"
import { connect } from "react-redux"
import Item from "./Item"
import Checkout from "./Checkout"
import { applyDiscountAction } from "../../store/actions/cart"


const WhiteBlockContainer = styled.div`
    width: 100%;
    background: white;
    position: relative;
    padding-left: 0px;
    padding-right: 0px;
`;
const ContentContainer = styled(Container)`
    padding-top: 100px;
    padding-bottom: 100px;    
    box-sizing: border-box;
    @media(max-width: 768px) {
        padding-bottom: 50px;
        padding-top: 50px;
    }
`;
const RowStyled = styled(Row)`
    padding-bottom: 15px;
    padding-top: 15px;
    margin-left: 0px;
    margin-right: 0px;
    max-width: ${props => props.filterRow ? '500px' : ''};
    margin: auto;
    border-bottom: ${props => props.underline ? '1px dashed #9CA8B5' : 'none'};
    position: relative;
`;
const ColStyled = styled(Col)`
    box-sizing: border-box;
    padding-left: 0px;
    padding-right: 0px;
    position: relative;
    text-align: ${props => props.center ? 'center' : ''};
    display: ${props => props.flex ? 'flex' : ''};
    align-items: ${props => props.flex ? 'center' : ''};
    justify-content: ${props => props.flex ? 'center' : ''};
    justify-content: ${props => props.end ? 'flex-end !important' : ''};
    justify-content: ${props => props.start ? 'flex-start !important' : ''};
    @media(max-width: 768px) {
        order: ${props => props.controlled0 ? '0' : ''};
        order: ${props => props.controlled1 ? '1' : ''};
        display: ${props => props.desktop ? 'none' : ''};
    };
    @media(min-width: 992px) {
        padding-top: ${props => props.middle === true ? '120px' : '0px'};
    }
    padding-bottom: ${props => props.productCol ? '45px' : ''};
`;

const ColumnName = styled.div`
    ${fonts.swissBlackExtended};
    color:  #222E3C;
    text-transform: uppercase;
    font-size: 14px;
`;

class WhiteBlock extends Component {
    state = {
        item: 1,
        coupon: '',
        showForm: false,
        hasError: false
    }

    addItem = (props) => {
        if ((this.state.item < 1 || this.state.item === 1) && props === -1) {
            this.setState({
                item: 1
            })
        } else {
            this.setState({
                item: this.state.item + props
            })
        }
    }
    updateCoupon = (e) => {
        this.setState({
          coupon: e.target.value
        });
      };
    
      applyCoupon = (e) => {
        e.preventDefault();
    
        const discount = this.props.applyDiscountAction(this.state.coupon);
    
        discount && discount.then(() => {
          if (!this.props.cart.isUpdated) {
            this.setState({
              hasError: true
            });
          } else {
            this.setState({
              showForm: false
            });
          }
        });
      };

    render() {
    let cartItems
    let cartData
    let cart

    const isBrowser = typeof window !== "undefined"

    if (isBrowser) {
      cartData = getCart()
    }

    if (cartData) {
      cart = JSON.parse(cartData)
      cartItems = cart.items
    } else {
      cartItems = []
    }

    const {applyCoupon, updateCoupon} = this;
    const {showForm, coupon, hasError} = this.state;
        return (
            <WhiteBlockContainer>
                <ContentContainer>
                    <RowStyled>
                        <ColStyled md={7}>
                            <RowStyled underline>
                                <ColStyled xs={4} sm={4} md={4}><ColumnName>Product</ColumnName></ColStyled>
                                <ColStyled xs={4} sm={4} md={4}><ColumnName>Qty</ColumnName></ColStyled>
                                <ColStyled xs={4} sm={4} md={4}><ColumnName>Price</ColumnName></ColStyled>
                            </RowStyled>
                            {cartItems.map((item, index) => <Item item={item} key={index} />)}
                        </ColStyled>
                        <ColStyled md={5}>
                            <Checkout cart={cart} applyCoupon={applyCoupon} updateCoupon={updateCoupon} showForn={showForm} coupon={coupon} hasError={hasError}/>
                        </ColStyled>
                    </RowStyled>
                </ContentContainer>
            </WhiteBlockContainer>
        )
    }
}

const mapStateToProps = state => {
    return {
      cart: getCheckout(state),
    }
  };
  
  const mapDispatchToProps = {
    applyDiscountAction,
  };
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps,
  )(WhiteBlock)
  
