import React from "react"
import styled from "styled-components"
import DeleteImg from '../../resources/images/net/delete-cross.svg';
import DeleteImgMobile from '../../resources/images/net/delete-cross-mobile.svg';
import { Row, Col } from 'styled-bootstrap-grid';
import fonts from '../../styles/fonts';
import { updateItemAction, removeItemAction } from "../../store/actions/cart/index"
import { getRemovingItems, getRemovedItems, isCartUpdating } from "../../store/reducers"
import { connect } from "react-redux"

const RowStyled = styled(Row)`
    padding-bottom: 15px;
    padding-top: 15px;
    margin-left: 0px;
    margin-right: 0px;
    max-width: ${props => props.filterRow ? '500px' : ''};
    margin: auto;
    border-bottom: ${props => props.underline ? '1px dashed #9CA8B5' : 'none'};
    position: relative;
`;
const ColStyled = styled(Col)`
    box-sizing: border-box;
    padding-left: 0px;
    padding-right: 0px;
    position: relative;
    text-align: ${props => props.center ? 'center' : ''};
    display: ${props => props.flex ? 'flex' : ''};
    align-items: ${props => props.flex ? 'center' : ''};
    justify-content: ${props => props.flex ? 'center' : ''};
    justify-content: ${props => props.end ? 'flex-end !important' : ''};
    justify-content: ${props => props.start ? 'flex-start !important' : ''};
    @media(max-width: 768px) {
        order: ${props => props.controlled0 ? '0' : ''};
        order: ${props => props.controlled1 ? '1' : ''};
        display: ${props => props.desktop ? 'none' : ''};
    };
    @media(min-width: 992px) {
        padding-top: ${props => props.middle === true ? '120px' : '0px'};
    }
    padding-bottom: ${props => props.productCol ? '45px' : ''};
`;

const ItemImage = styled.img`
    width: 95px;
    height: 105px;
    
`;
const ItemTitle = styled.div`
    ${fonts.gilroyRegular};
    font-size: 15px;
    line-height: 21px;
    color:  #222E3C;
`;
const ItemPrice = styled.div`
    ${fonts.gilroyBold};
    font-size: 15px;
    line-height: 21px;
    color:  #222E3C;
`;
const DeleteImage = styled.img`
    height: 21px;
    width: 21px;
    @media(min-width: 768px) {
        height: 10px;
        width: 10px;
    }
`;
const DeleteItem = styled.button`
    background: transparent;
    border: none;
    cursor: pointer;
        &:hover,
        &:active,
        &:focus {
            text-decoration: none;
            outline: none;
            box-shadow: none;
        }
`;
const CountContainer = styled.div`
    width: 100px;
    height: 44px;
    border: 1px solid #D1D1D1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin: auto;
`;
const AddItemButton = styled.button`
    cursor: ${props => props.disabled ? 'initial !important' : 'pointer'};
    text-decoration: none;
    border: none;
    background: transparent;
    border-radius: none;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 20px;
    color: ${props => props.disabled ? '#D1D1D1' : '#222E3C'};
    ${fonts.gilroyRegular};
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        outline: none;
        box-shadow: none;
        background-color: transparent;
    }
`;
const ItemCount = styled.div`
    color: #222E3C;
    ${fonts.gilroyRegular};
    letter-spacing: 2.77px;
    font-size: 15px;
    line-height: 15px;
`;

const DeleteMobileContainer = styled.div`
    position: absolute;
    top: 5px;
    left: -10px;
    z-index: 99;
    @media(min-width: 768px) {
        display: none;
    }
`;

const Item = (props) => {
  const {item} = props
  const {quantity, price, handle} = item
  const { updateItemAction, removeItemAction} = props
  const totalItemPrice = Number(quantity * price).toFixed(2)
  const beer = handle.includes("ale")
  const sizeConverter = () => {
    const selectedOption = item.selectedOptions[0].value
    if (selectedOption === "S") {
      return "Small"
    }
    if (selectedOption === "M") {
      return "Medium"
    }
    if (selectedOption === "L") {
      return "Large"
    }
    if (selectedOption === "XL") {
      return "XLarge"
    }
  }
  const itemSize = sizeConverter()

  const updateCartItem = (quantity) => {
    const { lineItemId } = item

    updateItemAction(
      lineItemId,
      quantity,
    )
  }

  const removeItemFromCart = () => {
    const { lineItemId } = item

    removeItemAction(lineItemId)
  }

  const updateItem = (event, quantityToAdd) => {
    event.preventDefault()

    const { isUpdating } = props

    if (isUpdating) {
      return
    }

    const newQuantity = quantity + quantityToAdd

    if (newQuantity > 0) {
      updateCartItem(newQuantity)
    } else {
      removeItemFromCart()
    }
  }

  const addItem = (e) => {
    return updateItem(e, 1)
  }

  const removeItem = (e) => {
    return updateItem(e, -1)
  }

  const deleteItem = (e) => {
    e.preventDefault()

    return removeItemFromCart()
  }

  return (
    <RowStyled underline>
      <DeleteMobileContainer>
        <DeleteItem onClick={(e) => deleteItem(e)}>
          <DeleteImage src={DeleteImgMobile} />
        </DeleteItem>
      </DeleteMobileContainer>
      <ColStyled xs={4} sm={4} md={3} flex start>
        <ItemImage src={item.image} beer={beer} />
      </ColStyled>
      <ColStyled xs={4} sm={4} md={3} flex>
        <ItemTitle>{item.title} {itemSize}</ItemTitle>
      </ColStyled>
      <ColStyled xs={4} sm={4} md={3} flex>
        <CountContainer>
          <AddItemButton
            onClick={(e) => removeItem(e)}
            disabled={item.quantity <= 1}
          >
            -
          </AddItemButton>
          <ItemCount> {item.quantity}</ItemCount>
          <AddItemButton
            onClick={(e) => addItem(e)}
          >
            +
          </AddItemButton>
        </CountContainer>
      </ColStyled>
      <ColStyled xs={2} sm={2} md={2} flex desktop>
        <ItemPrice>{totalItemPrice}</ItemPrice>
      </ColStyled>
      <ColStyled xs={1} sm={1} md={1} flex end desktop>
        <DeleteItem onClick={(e) => deleteItem(e)}>
          <DeleteImage src={DeleteImg} />
        </DeleteItem>
      </ColStyled>
    </RowStyled>
  )
}

const mapStateToProps = state => {
  return {
    isUpdating: isCartUpdating(state),
    removing: getRemovingItems(state),
    removed: getRemovedItems(state),
  }
}

const mapDispatchToProps = {
  updateItemAction,
  removeItemAction,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Item)
