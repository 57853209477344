import React from "react"
import styled from "styled-components"
import fonts from '../../styles/fonts';
import { getTotal } from "../../services/cart/cart"
import Coupon from "./Coupon"

const CheckoutContainer = styled.div`
    background: #222E3C;
    height: 435px;
    max-width: 363px;
    width: 90%;
    margin: auto;
    margin-top: 44px;
    @media(max-width: 768px) {
        width: 100%;
    }
`;
const CheckoutContentContainer = styled.div`
    padding-top: 55px;
    padding-left: 40px;
    padding-right: 40px;
    @media(max-width: 768px) {
        width: 90%;
        margin: auto;
        padding-left: 0;
        padding-right: 0;
        max-width: 290px;
    }
`;

const TitleText = styled.div`
    ${fonts.gilroyBold};
    color: white;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 2.77px;
    padding-bottom: 10px;
    padding-top: 9px;
`;
const SubtitleContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: ${props => props.bottom ? '25px' : ''};
`;
const SubtitleText = styled.div`
    ${fonts.gilroyRegular};
    font-size: 14px;
    line-height: 27px;
    color: white;
`;
const CheckoutLink = styled.div`
    cursor: pointer;
    font-size: 12px;
    letter-spacing: 2.77px;
    ${fonts.gilroyBold};
    text-transform: uppercase;
    text-decoration: none;
    color: white;
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        outline: none;
        box-shadow: none;
    } 
`;
const CheckoutText = styled.div`
    cursor: pointer;
    height: 44px;
    max-width: 280px;
    width: 100%;
    border: 1px solid #9DDCE2;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    position: relative;
`;
const BorderStyled = styled.div`
    border-bottom: 5px solid #9DDCE2;
    border-right: 5px solid #9DDCE2;
    max-width: 277px;
    width: 100%;
    height: 39px;
    position: absolute;
    bottom: -5px;
    right: -5px;
`;


const Checkout = ({cart, applyCoupon, updateCoupon, showForm, coupon, hasError}) => {
  const totalPrice = cart ? Number(getTotal(cart)).toFixed(2) : "0.00";
  const checkoutUrl = cart ? cart.checkoutUrl : "/"
  const originalTotal = cart ? Number(cart.checkout.lineItemsSubtotalPrice.amount).toFixed(2) : "0.00";
  const discount = totalPrice !== originalTotal ? Number(originalTotal - totalPrice).toFixed(2) : "0.00"

  const isBrowser = typeof window !== "undefined"

  const handleClick = (e) => {
    if (isBrowser) {
      applyCoupon(e)
      setTimeout(() => {
        window.open(checkoutUrl, "_self")   
      }, 1000);
    }
  }

  return (
    <CheckoutContainer>
      <CheckoutContentContainer>
        <Coupon cart={cart} applyCoupon={applyCoupon} updateCoupon={updateCoupon} showForn={showForm} coupon={coupon} hasError={hasError}/>
        <SubtitleContainer>
          <SubtitleText>Subtotal</SubtitleText>
          <SubtitleText>£{originalTotal}</SubtitleText>
        </SubtitleContainer>
        <SubtitleContainer>
          <SubtitleText>Discount</SubtitleText>
          <SubtitleText>£{discount}</SubtitleText>
        </SubtitleContainer>
        <SubtitleContainer>
          <SubtitleText>Estimate Shipping</SubtitleText>
          <SubtitleText>£0.00</SubtitleText>
        </SubtitleContainer>
        <SubtitleContainer bottom>
          <TitleText>Total:</TitleText>
          <TitleText>£{totalPrice}</TitleText>
        </SubtitleContainer>
        <CheckoutLink onClick={(e) => handleClick(e)}>
          <CheckoutText>
            Checkout <BorderStyled />
          </CheckoutText>
        </CheckoutLink>
      </CheckoutContentContainer>
    </CheckoutContainer>
  )
}

export default Checkout
