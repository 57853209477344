import React, { Component } from 'react';
import styled from 'styled-components';
import Layout from '../components/core/Layout';
import SEO from '../components/core/Seo';
import Hero from '../components/net/Hero';
import WhiteBlock from '../components/net/WhiteBlock';
import PrivateRoute from "../router/PrivateRoute"


const LayoutStyled = styled(Layout)`
  margin: 0px;
`;

class Product extends Component {
  state = {}

  render() {
    const path = this.props.location.pathname;
    return (
      <LayoutStyled>
        <SEO title="Lost At Sea"
          description="A storm is brewing." fullTitle={true}
          path={path} />
        <Hero />
        <WhiteBlock />
      </LayoutStyled>
    )
  }
}

function net(props) {
  return <PrivateRoute component={Product} {...props}/>
}

export default net;
