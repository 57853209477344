import React from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import check from "../../resources/images/ui/check.svg"

const TitleText = styled.div`
  ${fonts.gilroyBold};
  color: white;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 2.77px;
  padding-bottom: 10px;
  padding-top: 9px;
`

const CouponForm = styled.form`
  position: relative;
`

const InputStyled = styled.input`
  text-decoration: none;
  border: none;
  border-radius: 0;
  outline: none;
  background: rgba(255, 255, 255, 0.1);
  height: 48px;
  max-width: 285px;
  width: 100%;
  margin-bottom: 30px;
  ${fonts.gilroyRegular};
  font-size: 14px;
  line-height: 27px;
  color: white;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  :placeholder {
    ${fonts.gilroyRegular};
    font-size: 14px;
    line-height: 27px;
    color: white;
    padding-left: 15px;
    padding-right: 15px;
  }
  &:hover,
  &:focus,
  &:active {
    -webkit-tap-highlight-color: transparent;
    text-decoration: none;
  }
`
const Success= styled.div`
  position: absolute;
  top: 0;
  right: 18px;
  height: 48px;
  display: flex;
  justify-content: center;
`

const Coupon = props => {
  const { cart, applyCoupon, updateCoupon, coupon } = props;
  const appliedCode = cart ? cart.couponCode : ""

  return (
    <>
      <TitleText>Discount /Voucher code:</TitleText>
      <CouponForm onSubmit={applyCoupon}>
        <InputStyled value={coupon ? coupon : appliedCode} onChange={updateCoupon} />
        {cart && cart.couponCode && (
          <Success>
            <img src={check} alt="success" />
          </Success>
        )}
      </CouponForm>
    </>
  )
}

export default Coupon
